<template>
    <div class="p-2" style="border-radius: 10px; background-color: #ffffff;">
        <div>
            <img :src="payload.image_url"
                class="img-fluid campaign-banner" alt="">
        </div>
        <div class="mt-2">
            <div class="font-weight-bold font-donation-big text-truncate">{{ payload.campaign_title }}</div>
            <div class="text-muted">{{ payload.location }}</div>

            <div class="progress mt-3">
                <div class="progress-bar" role="progressbar" :style="{'width': payload.percentage_funded + '%', 'background-color': '#00BF99'}" aria-valuenow="25" aria-valuemin="0"
                    aria-valuemax="100"></div>
            </div>

            <div class="mt-2 text-muted">
                Terkumpul <b class="color-primer">Rp. {{ Number(payload.funded).toLocaleString('id') }}</b>
            </div>
        </div>
    </div>
</template>

<style scoped>
.campaign-banner {
    border-radius: 30px;
}
</style>

<script>

export default {
    name: 'CampaignComponent',
    props: ['payload']
}

</script>