<template>
    <div class="px-4 py-2 bg-white rounded" v-if="payload.id">
        <div class="row">
            <!-- <div class="col-4">
                <img src="https://static.thenounproject.com/png/4035889-200.png" class="img-fluid" alt="">
            </div> -->
            <div class="col" v-if="payload.user">
                <div class="font-weight-bold">
                    {{payload.user.name}}
                </div>
                <div class="text-muted">
                    {{payload.time}}
                </div>
                <div class="text-truncate">
                    <div @click.prevent="goDetailCampaign(payload.campaign.id)">
                        <span class="text-primary">{{ payload.campaign.campaign_title }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col text-truncate">
                <div>
                    <p class="font-italic">{{ payload.doa_text }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'DoaComponent',
    props: ['payload'],

    methods: {
        goDetailCampaign(id) {
            this.$router.push('/campaign/' + id);
        }
    }
}

</script>