<template>
    <div>
        <div v-if="!campaign.id">
            <div style="margin-top: 400px;">
                <vue-element-loading active spinner="bar-fade-scale" color="#31a05f" />
            </div>
        </div>
        <div v-else>
            <div class="row">
                <div class="text-center col-auto" @click="goBack()">
                    <font-awesome-icon  icon="fa-solid fa-arrow-left" />
                </div>
                <div class="text-center col font-weight-bold">Detail Donasi</div>
                <div class="text-center col-auto"></div>
            </div>
            
            <div class="row mt-4">
                <div class="col">
                    <img class="img-fluid" style="height: 200px; width: 100%; object-position: center; border-radius: 10px;" :src="campaign.imageUrl" alt="">
                </div>
            </div>
    
            <div class="row mt-2">
                <div class="col">
                    <div class="font-weight-bold font-donation-big">
                        {{ campaign.title }}
                    </div>
                    <div class="text-muted">
                        {{ campaign.location }}
                    </div>
                </div>
            </div>
    
            <div class="row mt-4">
                <div class="col">
                    <div class="font-weight-bold color-primer">
                        Rp. {{ campaign.goalAmount.toLocaleString('id') }}
                    </div>
                    <div class="font-donation-small">
                        Terkumpul <span class="font-weight-bold">Rp. {{ campaign.currentAmount.toLocaleString('id') }}</span>
                    </div>
                    <div class="progress mt-3">
                        <div class="progress-bar" role="progressbar" :style="{'background-color': '#00BF99', 'width': campaign.percentageFunded + '%'}" aria-valuenow="25" aria-valuemin="0"
                            aria-valuemax="100"></div>
                    </div>
                    <div class="mt-4">
                        <p class="text-muted font-donation-small text-justify">
                            {{ campaign.description }}
                        </p>
                    </div>
                    <div class="mt-4" v-if="campaign.isFavorite == false">
                        <button @click="setAsFavorite()" class="btn btn-block text-white py-2" style="background-color: #00BF99;">Tandai Sebagai Favorit</button>
                    </div>
                    <div class="mt-4" v-else>
                        <button @click="removeAsFavorite()" class="btn btn-block text-white py-2" style="background-color: #00BF99;">Hapus Sebagai Favorit</button>
                    </div>
                </div>
            </div>
    
            <section v-if="doas.length > 0">
                <div class="row mt-4">
                    <div class="col">
                        <span class="font-weight-bold">Doa Orang Baik</span>
                    </div>

                    <!-- <div class="col-10 live__scroll--box" v-for="doa in doas" :key="doa.id">
                        <DoaComponent :payload="doa" />
                    </div> -->
                </div>
    
                <div class="live__scroll">
                    <div class="row mt-4">
                        <!-- <div class="col-6 live__scroll--box">
                            <div class="px-4 py-2 rounded">
                                <div class="row mt-2">
                                    <div class="col p-2 text-truncate">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <img :src="doaAsset" class="img-fluid" alt="">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col p-2 text-truncate">
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-10 live__scroll--box" v-for="doa in doas" :key="doa.id">
                            <DoaComponent :payload="doa" />
                        </div>
                    </div>
                </div>
            </section>
    
            <section class="mt-4">
                <div class="row mt-4">
                    <div class="col">
                        <span class="font-weight-bold">Donasi Lain Yang Tepat Untuk Anda</span>
                    </div>
    
                </div>
    
                <div class="live__scroll">
                    <div class="row mt-4">
                        <div class="col-10 live__scroll--box" v-for="campaignItem in campaigns" :key="campaignItem.id">
                            <CampaignComponent :payload="campaignItem" @click.native="toDetailCampaign(campaignItem.id)" />
                        </div>
                    </div>
                </div>
            </section>
    
            <br>
            <br>
            <br>
    
            <div class="row fixed-bottom p-2 bg-white" style="border-top: 2px solid #fefefe;">
                <div class="col">
                    <button @click="goDetailFund(campaign.id)" class="btn btn-block text-white py-2" style="background-color: #00BF99;">Donasi Sekarang</button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faArrowLeft)
import CampaignComponent from '../../components/v2/CampaignComponent.vue';
import DoaComponent from '../../components/v2/DoaComponent.vue';
import doaAsset from '../../assets/doa.png';
import axios from 'axios';

export default {
    name: 'CampaignDetail',
    
    components: {
        DoaComponent,
        CampaignComponent,
    },

    watch: {
        '$route.params.slug': 'reloadPageDetailCampaign',
    },

    methods: {
        setAsFavorite() {
            axios.post(process.env.VUE_APP_API + 'campaign/' + this.$route.params.slug + '/favorite', null, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            }).then((resp) => {
                if(resp.status == 200) {
                    this.campaign.isFavorite = true;
                }
            });
        },

        removeAsFavorite() {
            axios.delete(process.env.VUE_APP_API + 'campaign/' + this.$route.params.slug + '/favorite', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            }).then((resp) => {
                console.info(resp);
                if(resp.status == 200) {
                    this.campaign.isFavorite = false;
                }
            });
        },

        goBack() {
            this.$router.go(-1);
        },

        goDetailFund(id) {
            this.$router.replace('/campaign/' + id + '/fund');
        },

        toDetailCampaign(id) {
            this.$router.replace('/campaign/' + id);
        }, 

        reloadPageDetailCampaign() {
            this.campaign.id = '';

            this.doas = [];
            this.campaigns = [];
            this.getCampaignDetail();
            this.getCampaigns();
            this.getDoaInCampaign();
        },

        getCampaignDetail() {
            axios.get(process.env.VUE_APP_API + 'campaign/' + this.$route.params.slug, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            }).then((res) => {
                if(res.status == 200) {
                    const data = res.data.data;

                    console.info(data);

                    this.campaign.id = data.id;
                    this.campaign.imageUrl = data.path;
                    this.campaign.location = data.location;
                    this.campaign.currentAmount = data.funded;
                    this.campaign.goalAmount = data.goal_amount;
                    this.campaign.description = data.description;
                    this.campaign.title = data.campaign_title;
                    this.campaign.percentageFunded = data.percentage_funded;
                    this.campaign.isFavorite = data.is_favorite;
                }
            })
        },

        getDoaInCampaign() {
            axios.get(process.env.VUE_APP_API + 'campaign/' + this.$route.params.slug + '/doa', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            }).then((res) => {
                if(res.status == 200) {
                    const data = res.data.data;

                    data.forEach(element => {
                        this.doas.push({
                            id: element.id,
                            doa_text: element.doa_text,
                            time: element.time,
                            campaign: {
                                id: element.campaign.id,
                                campaign_title: element.campaign.campaign_title,
                            },
                            user: {
                                name: element.user.name,
                            }
                        })
                    });
                }
            })
        },

        getCampaigns() {
            axios.get(process.env.VUE_APP_API + 'campaigns', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
                params: {
                    limit: 10,
                }
            }).then((resp) => {
                if(resp.status == 200) {
                    const data = resp.data.data;

                    data.forEach(element => {
                        if(element.id != this.$route.params.slug) {
                            this.campaigns.push({
                                id: element.id,
                                campaign_title: element.campaign_title,
                                location: element.location,
                                description: element.description,
                                goal_amount: element.goal_amount,
                                funded: element.funded,
                                percentage_funded: element.percentage_funded,
                                image_url: element.path,
                            });
                        }
                    });
                }
            })
        },

        mappingToEntity(entity) {
            this.campaign.id = entity.id;
            this.campaign.imageUrl = entity.path;
            this.campaign.location = entity.location;
            this.campaign.currentAmount = entity.funded;
            this.campaign.goalAmount = entity.goal_amount;
            this.campaign.description = entity.description;
            this.campaign.title = entity.campaign_title;
            this.campaign.percentageFunded = entity.percentage_funded;
        }

    },
    
    beforeMount() {
        this.getCampaignDetail();
        this.getCampaigns();
        this.getDoaInCampaign();
    },

    data() {
        return {
            doaAsset,
            campaign: {
                id: '',
                imageUrl: '',
                title: '',
                location: '',
                currentAmount: 0,
                goalAmount: 0,
                description: '',
            },

            campaigns: [],
            doas: [],
        }
    }
}

</script>

<style>
.live__scroll {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.live__scroll .row {
    display: block;
}

.live__scroll .live__scroll--box {
    display: inline-block;
    float: none;
}
</style>